import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import App from './app';
import {AuthProvider} from './context/auth-context';
import './styles.css';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <BrowserRouter>
    <AuthProvider>
      <App />
    </AuthProvider>
  </BrowserRouter>
);


/*console.log('One does not simply walk into Mordor!');

import React, { Component } from 'react';
import {render} from 'react-dom';
import queryString from 'query-string';

import config from './config.js';

import {
  BrowserRouter as Router,
  Route,
  Link,
  Switch
} from 'react-router-dom';

import {history, PrivateRoute, cookies} from './lib';

import {
  Home,
  Itonics,
  ItonicsExplorer,
  ItonicsNew,
  Login
} from './pages';

import {
  ApiService,
  AuthService,
  ItonicService,
  ItonicsService,
  PrintService
} from './services';

import {Navigation} from './components';

const services = {
  api: new ApiService(config),
  auth: new AuthService(config),
  itonic: new ItonicService(config),
  itonics: new ItonicsService(config),
  print: new PrintService(config)
};

const routes = [
  {
    route:'P',
    component: Home,
    path: '/',
    name: 'Home',
    showInNavbar: false,
    navbar: true,
    exact:true
  },
  {
    route:'R',
    component: Login,
    path: '/login',
    name: 'Login',
    showInNavbar: false,
    navbar: false
  },
  {
    route:'P',
    component: ItonicsNew,
    path: '/itonics-new',
    name: 'Itonics Pusher',
    showInNavbar: true,
    navbar: true
  },
  {
    route:'P',
    component: Itonics,
    path: '/itonics',
    name: 'Itonics Pusher (Legacy)',
    showInNavbar: false,
    navbar: true
  },
  {
    route:'P',
    component: ItonicsExplorer,
    path: '/itonics-explorer',
    name: 'Itonics Explorer',
    showInNavbar: false,
    navbar: true
  }
];


const NotFound = (props) => {
  return <div>PAGE NOT FOUND</div>
}

const addPropsToRoute = (TargetComponent, componentProps) => {
  return (
    class ComponentRoute extends React.Component {
      render() {
        const props = Object.assign({}, this.props, componentProps);
        return  (<TargetComponent {...props} />);
      }
    }
  );
};

class App extends Component {
  constructor(props) {
    super(props);

    const qs = queryString.parse(window.location.search);
    if (qs.token) {
      cookies.set('token', qs.token);
    }

    this.state = {
      ready: false,
    };

    this.onValidate = this.onValidate.bind(this);
  }

  componentDidMount() {
  }

  componentDidUpdate(prevProps) {
    //if ((window.location !== prevProps.location) && !this.props.location.hash) {
      // window.scrollTo(0, 0);
      // document.body.style.removeProperty('overflow');
    //}
  }

  onValidate(state) {
    this.setState(Object.assign(state, {ready: true}));
  }

  render() {
    return (
      <div className="log-manager">

        <Router history={history}>
            <div className="wrapper">
              {this.state.ready && (<Switch>
                {routes.map((r, i) => {
                  if (r.navbar) {
                    return (
                      <Route
                        key={i}
                        path={r.path}
                        component={addPropsToRoute(
                          Navigation, {
                            routes: routes
                          }
                        )}
                      />
                    );
                  } else {
                    return '';
                  }
                })}
              </Switch>)}

              <main>
                <Switch>
                  {routes.map((r, i) =>{
                    if (r.route === 'R') {
                      return (<Route
                        key={i}
                        path={r.path}
                        exact={r.exact}
                        component={addPropsToRoute(r.component, {
                          services
                        })}
                      />);
                    } else if (r.route === 'P') {
                      return (<PrivateRoute
                        key={i}
                        path={r.path}
                        exact={r.exact}
                        component={r.component}
                        authService={services.auth}
                        validation="admin"
                        services={services}
                        onValidate={this.onValidate}
                      />);
                    }
                  })}

                  <Route component={NotFound}/>
                </Switch>
              </main>
            </div>
        </Router>
      </div>);
    }
}

// export default App;
render(<App/>, document.getElementById('root'));*/
