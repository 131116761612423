let authUrl = 'https://auth2.nextatlas.com/api/v3';
let apiUrl = 'https://api.nextatlas.com/v1';
let itonicsUrl = 'https://itonics.nextatlas.com/api';

const getBaseDomain = () => {
  const { hostname } = window.location;
  const domainParts = hostname.split('.').slice(-2).join('.');  // Get the base domain (example.com from app.example.com)
  return domainParts;
};

let logMonitorUrl = '/api/v0';

if (getBaseDomain() === 'localhost') {
  // logMonitorUrl = 'http://localhost:1999/api/v0';
  logMonitorUrl = 'https://lotr.nextatlas.com/api/v0';
}
// let logMonitorUrl = 'http://localhost:1999/api/v0';

let printUrl = 'https://print2.nextatlas.com/api';
// let printUrl = 'http://localhost:9012/api';

// const operation_id = 'a99d743f-bb53-4dd6-9248-4289b20cbb7f';
const operation_id = 'ebabbbc4-850a-431e-aed2-7b523c7401e1';
const prev_opId = '0bd32e1c-d2cb-478c-8a86-a26643a243ab';

export default {
  apiUrl,
  authUrl,
  itonicsUrl,
  logMonitorUrl,
  printUrl,
  operation_id,
  prev_opId
};
