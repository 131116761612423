class Service {
  constructor(base, headers) {
    this.base = base;
    this.headers = headers || {};
    this.datasource = '';
  }

  setHeader(header, value) {
    this.headers[header] = value;
    return this;
  }

  clearHeaders() {
    this.headers = {};
  }

  setDatasource(datasource) {
    this.datasource = datasource;
    return this;
  }

  processUrl(url, datasource) {
    let processedUrl = this.base;
    if (datasource || this.datasource) {
      processedUrl += '/' + (datasource || this.datasource) + url;
    } else {
      processedUrl += url;
    }

    return processedUrl;
  }

  get(url) {
    const config = {};
    config.headers = this.headers;
    return this.execute(this.serviceGet(this.processUrl(url), config.headers));
  }

  getNoCors(url) {
    return new Promise((resolve, reject) => {
      fetch(this.processUrl(url))
        .then(response => {
          if (!response.ok) {
            return reject(response.statusText);
          }
          return response.json();
        })
        .then(data => {
          return resolve(data);
        })
        .catch(error => {
          return reject('error');
        });
    });
  }

  post(url, payload, datasource) {
    const config = {};
    config.headers = this.headers;
    return this.execute(this.servicePost(this.processUrl(url, datasource), payload, config.headers));
  }

  delete(url) {
    const config = {};
    config.headers = this.headers;
    return this.execute(this.servicePost(this.processUrl(url), {}, config.headers));
  }

  execute(promise) {
    return new Promise((resolve, reject) => {
      promise.then(r => {
        return resolve(r.response || r);
      }).catch(err => {
        return reject(this.getError(err));
      });
    });
  }

  getError(httpErr) {
    let error = {};

    if (httpErr.response && httpErr.response.data) {
      if (httpErr.response.data.error) {
        if (typeof httpErr.response.data.error === 'string') {
          error.message = httpErr.response.data.error;
        } else {
          error = httpErr.response.data.error;
        }
      } else {
          error = httpErr.response.data;
      }
    } else {
      error.message = 'error';
    }

    return error;
  }

  serviceGet(url, headers) {
    console.log(url);
    return new Promise((resolve, reject) => {
      fetch(url, {
        method: 'GET', // or 'PUT'
        headers: {
          ...headers,
          ...{'Content-Type': 'application/json'},
        },
      })
        .then(response => {
          if (!response.ok) {
            return reject(response.statusText);
          }
          return response.json();
        })
        .then(data => {
          return resolve(data);
        })
        .catch(error=> {
          return reject('error');
        });
    });
  }

  servicePost(url, postData, headers) {
    return new Promise((resolve, reject) => {
      fetch(url, {
        method: 'POST', // or 'PUT'
        headers: {
          ...headers,
          ...{'Content-Type': 'application/json'},
        },
        body: JSON.stringify(postData)
      }).then(response => {
        if (!response.ok) {
          return reject(response.statusText);
        }
        return response.json();
      }).then(data => {
        return resolve(data);
      }).catch(error=> {
        return reject('error');
      });
    });
  }
}

export default Service;
