import Service from '../lib/Service';
import config from '../config';

class ItonicsLogService extends Service {
  constructor() {
    super(config.logMonitorUrl);
  }

  fetchLogs() {
    return this.get('/itonics/logs');
  }

  fetchNewLogs() {
    return this.get('/itonics-new/logs');
  }
}

export default ItonicsLogService;
