import React, { useEffect, useState } from 'react';
import { useAuth } from '../context/auth-context';
import LoadingSpinner from '../components/loading-spinner';

const OUCOME_BASE_URL = `https://app.nextatlas.com/nexttrend/outcome`;

const Modal = (props) => {
  return (
    <div
      className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50"
      onClick={() => props.closeModal()}
    >
      <div
        className="modal-content max-w-4xl mx-auto p-6 nxt-box overflow-auto max-h-[80vh]"
        onClick={(event) => event.stopPropagation()}
      >
        <h3 className="text-xl mb-4">Errored Items</h3>
        <table className="min-w-full border-collapse">
          <thead>
            <tr>
              <th className="py-2 px-4 text-center border-b font-semibold">Operation</th>
              <th className="py-2 px-4 text-center border-b font-semibold">Outcome ID</th>
              <th className="py-2 px-4 text-center border-b font-semibold">Type</th>
              <th className="py-2 px-4 text-center border-b font-semibold">Error</th>
            </tr>
          </thead>
          <tbody>
            {props.items.map((d, k) => (
              <tr key={k} className="hover:bg-gray-100">
                <td className="py-2 px-4 border-b">{d.operation}</td>
                <td className="py-2 px-4 border-b">
                  {d.uuid_next_atlas && d.uuid_next_atlas !== 'NEW' ? (
                    <a
                      href={`${OUCOME_BASE_URL}/${d.uuid_next_atlas}`}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-teal-600 hover:text-teal-700 cursor-pointer"
                    >
                      {d.uuid_next_atlas}
                    </a>
                  ) : (
                    '---'
                  )}
                </td>
                <td className="py-2 px-4 border-b">{d.type}</td>
                <td className="py-2 px-4 border-b font-mono text-xs">{typeof d.error === 'string' ? d.error : 'cannot display error'}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

const Itonics = (props) => {
  const { services } = useAuth();
  const [logs, setLogs] = useState(null);
  const [erroredItems, setErroredItems] = useState(null);

  useEffect(() => {
    services.itonicsLogService.fetchNewLogs().then((logs) => {
      setLogs(logs);
    });
  }, [services]);

  const openErrored = (items) => {
    setErroredItems([...items]);
  };

  const closeErrored = () => {
    setErroredItems(null);
  };

  const sortDates = (dates) => {
    dates.sort((a, b) => (a - b > 0 ? 1 : -1));
    return dates;
  };

  return (
    <div className="itonic px-4 py-6 space-y-6">
      <h1 className="text-3xl text-center">ITONICS PUSHER</h1>
      <div className="overall mb-6">
        <p className="text-lg text-gray-700 text-center">The system used to synchronize Nextatlas to Itonics</p>
      </div>
      {logs && (<div className="overall space-y-6">
        {logs &&
          Object.keys(logs).map((k) => (
            <div key={k} className="nxt-box">
              <h2 className="text-xl mb-4 capitalize">{k}</h2>
              <table className="min-w-full table-auto">
                <thead>
                  <tr>
                    <th className="py-2 px-4 text-center border-b font-semibold">Date</th>
                    <th className="py-2 px-4 text-center border-b font-semibold">Elapsed Time</th>
                    <th className="py-2 px-4 text-center border-b font-semibold">Processed</th>
                    <th className="py-2 px-4 text-center border-b font-semibold">Insert</th>
                    <th className="py-2 px-4 text-center border-b font-semibold">Update</th>
                    <th className="py-2 px-4 text-center border-b font-semibold">Errored</th>
                  </tr>
                </thead>
                <tbody>
                  {sortDates(Object.keys(logs[k])).map((day) => (
                    <tr key={`${k}-${day}`} className="hover:bg-gray-100">
                      <td className="py-2 px-4 border-b">{day}</td>
                      <td className="py-2 px-4 border-b text-center">{logs[k][day].elapsedSeconds}</td>
                      <td className="py-2 px-4 border-b text-center">{logs[k][day].processed}</td>
                      <td className="py-2 px-4 border-b text-center">{logs[k][day].insert}</td>
                      <td className="py-2 px-4 border-b text-center">{logs[k][day].update}</td>
                      <td className="py-2 px-4 border-b text-center">
                        {logs[k][day].errored > 0 && (
                          <span
                            className="text-teal-600 hover:text-teal-700 cursor-pointer"
                            onClick={() => openErrored(logs[k][day].erroredItems)}
                          >
                            Errored ({logs[k][day].errored})
                          </span>
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          ))}
      </div>)}

      {!logs && (<LoadingSpinner/>)}

      {erroredItems && (
        <Modal items={erroredItems} closeModal={() => closeErrored()} />
      )}
    </div>
  );
};

export default Itonics;