import React from 'react';
import { Navigate } from 'react-router-dom';
import { useAuth } from '../context/auth-context'; // Import your useAuth hook

const ProtectedRoute = ({ element: Component, ...rest }) => {
  const { token, account } = useAuth();

  if (!token || !account) {
    // If no account, redirect to login with a redirect query
    return <Navigate to={`/login?redirect=${encodeURIComponent(window.location.pathname)}`} />;
  }

  return <Component {...rest} />; // If authenticated, render the component
};

export default ProtectedRoute;
