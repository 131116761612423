const timelineChart = (title, series, plotBands) => {
  return {
    chart: {
      plotBackgroundColor: null,
      plotBorderWidth: null,
      plotShadow: false,
      // backgroundColor: 'transparent'
      zoomType: 'x'
    },
    title: {
        text: title,
        align: 'left'
    },
    legend: {
      layout: 'horizontal',
      align: 'left',
      verticalAlign: 'bottom'
    },
    xAxis: {
      type: 'datetime',
      zoomEnabled: true,
      labels: {
        formatter: function() {
          const date = new Date(this.value); // Convert the value to a Date object
          const options = { year: 'numeric', month: '2-digit' }; // Format options for 'MM/YYYY'
          return new Intl.DateTimeFormat('en-GB', options).format(date); // Format the date as 'MM/YYYY'
        }        
      },
      title: {
        align: 'high',
        text: '',
        style: {
          fontSize: '16px'
        },
      },
      plotBands
    },
    yAxis: {
      title: {
        text: '',
        align: 'high',
        style: {
          fontSize: '16px'
        },
      }
    },
    tooltip: {
      enabled: true
    },
    plotOptions: {
      series: {
          label: {
            connectorAllowed: false
          },
      }
    },
    series,
    credits: {
      enabled: false
    },
  }
};

const timelineBands = (dates, color, width, addFormat) => {
  color = color || '#FF4444';
  width = (width && width > 0) || 1;
  addFormat = addFormat || 'd';

  return [...new Set(dates.filter(d => d !== null && d !== undefined))].map(d => {
    const date = new Date(d); // Use native Date instead of moment
    const from = date.getTime();

    let to;
    if (addFormat === 'd') {
      to = new Date(date.setDate(date.getDate() + width)).getTime(); // Add days
    } else if (addFormat === 'm') {
      to = new Date(date.setMonth(date.getMonth() + width)).getTime(); // Add months
    } else if (addFormat === 'y') {
      to = new Date(date.setFullYear(date.getFullYear() + width)).getTime(); // Add years
    } else {
      to = from; // If no valid format is specified, just keep the same date
    }

    return {
      color,
      from,
      to,
      label: {
        // Uncomment and use if needed:
        //text: date.toLocaleDateString('en-GB'), // Example: formatting the date
        //align: 'left',
        //rotation: 90
      }
    };
  });
};


const timelineSerie = (objects, xPar, yPar, name, type) => {
  return {
    name: name || yPar,
    type: type || 'spline',
    data: objects
      .map(d => {
        const val = typeof d[yPar] === 'number' ? d[yPar] : parseInt(d[yPar]);
        return [new Date(d[xPar]).getTime(), val]; // Use `new Date()` instead of moment
      })
      .filter(d => !isNaN(d[1])) // Filter out invalid values
      .sort((a, b) => a[0] - b[0]) // Sort by the first value (timestamp)
  };
};

export default {
  timelineChart,
  timelineBands,
  timelineSerie
};
