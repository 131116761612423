import React, {useEffect, useState} from 'react';
import HighchartsReact from 'highcharts-react-official';
import Highcharts from 'highcharts';
import chartBuilder from '../lib/chartBuilder';
import { useAuth } from '../context/auth-context';
import LoadingSpinner from '../components/loading-spinner';

const Box = (props) => {
  const {chartData, logs, title} = props;

  return (
    <div className="column nxt-box">
      <HighchartsReact
        highcharts={Highcharts}
        options={chartData}
      />
      <ul className="mt-4 space-y-2">
        <li className="font-medium text-lg border-b pb-2">{title}</li>
        <li>{logs.length} runs, {logs.filter(d => d.error).length} errored</li>
        <li className="font-medium text-lg border-b pb-2">Last run</li>
        {Object.keys(logs[0]).map((k, i) => {
          if (k === 'file') {
            return '';
          }
          return (<li key={i} className="text-sm">
            <span className="font-medium">{k}</span>: <span>{logs[0][k]}</span>
          </li>)
        })}
      </ul>
    </div>
  )
}


const Itonics = (props) => {
  const { services } = useAuth();

  const [logs, setLogs] = useState(null);
  const [insifactChart, setInsifactChart] = useState(null);
  const [trendsChart, setTrendsChart] = useState(null);

  useEffect(() => {
    services.itonicsLogService.fetchLogs().then(logs => {

      const insifactSeries = [];
      const insifactBands = chartBuilder.timelineBands(logs.insifact.map(d => d.error ? d.date : null));

      insifactSeries.push(chartBuilder.timelineSerie(logs.insifact, 'date', 'items_synchronized'));
      insifactSeries.push(chartBuilder.timelineSerie(logs.insifact, 'date', 'of_which_new'));
      insifactSeries.push(chartBuilder.timelineSerie(logs.insifact, 'date', 'total_browsed'));
      insifactSeries.push(chartBuilder.timelineSerie(logs.insifact, 'date', 'items_failed'));
      insifactSeries.push(chartBuilder.timelineSerie(logs.insifact, 'date', 'elapsed_seconds'));

      setInsifactChart({...chartBuilder.timelineChart('insifact', insifactSeries, insifactBands)});

      const trendsSeries = [];
      const trendsBands = chartBuilder.timelineBands(logs.trends.map(d => d.error ? d.date : null));

      trendsSeries.push(chartBuilder.timelineSerie(logs.trends, 'date', 'items_synchronized'));
      trendsSeries.push(chartBuilder.timelineSerie(logs.trends, 'date', 'of_which_new'));
      trendsSeries.push(chartBuilder.timelineSerie(logs.trends, 'date', 'total_browsed'));
      trendsSeries.push(chartBuilder.timelineSerie(logs.trends, 'date', 'items_failed'));
      trendsSeries.push(chartBuilder.timelineSerie(logs.trends, 'date', 'elapsed_seconds'));

      setTrendsChart({...chartBuilder.timelineChart('trends', trendsSeries, trendsBands)});

      setLogs({...logs});
    })
  }, []);

  return (
    <div className="itonic px-4 py-6 space-y-6">
      <h1 className="text-3xl text-center">ITONICS PUSHER (LEGACY)</h1>
      <div className="overall mb-6">
        <p className="text-lg text-gray-700 text-center">The legacy system used to synchronize Nextatlas to Itonics (Last run: 10/3/2024)</p>
      </div>
      {logs && (<div className="charts grid grid-cols-1 sm:grid-cols-2 gap-6">
        <Box chartData={insifactChart} logs={logs.insifact} title="Insifacts"/>
        <Box chartData={trendsChart} logs={logs.trends} title="Trends"/>
      </div>)}

      {!logs && (<LoadingSpinner/>)}
    </div>
  );
  

}

export default Itonics;
