import React from 'react';

const LoadingSpinner = (props) => {
  return (<div className="flex justify-center items-center w-full h-64">
    <div className="relative w-16 h-16">
      <div className="absolute inset-0 border-4 border-gray-300 rounded-full"></div>
      <div className="absolute inset-0 animate-spin rounded-full border-4 border-t-4 border-transparent" style={{ borderTopColor: '#04ce73' }}></div>
    </div>
  </div>);
}

export default LoadingSpinner;
