import React from 'react';
import { Routes, Route, useLocation } from 'react-router-dom';
import ProtectedRoute from './lib/protected-route';

import Navigation from './components/navigation';

import Home from './pages/home';
import Itonics from './pages/itonics';
import ItonicsNew from './pages/itonics-new';
import Login from './pages/login';

const App = () => {
  
  const location = useLocation(); // Get the current route

  // Hide the NavBar on the login page
  const isLoginPage = location.pathname === '/login';

  return (<>
    {!isLoginPage && <Navigation/>}
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/login" element={<Login />} />
      <Route
        path="/itonics-new"
        element={<ProtectedRoute element={ItonicsNew} />}
      />
      <Route
        path="/itonics"
        element={<ProtectedRoute element={Itonics} />}
      />
    </Routes>
  </>
  );
}

export default App;
