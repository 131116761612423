import React from 'react';
import {Link} from 'react-router-dom';
import logo from '../assets/nextatlas.png';

const LINKS = [
  {name: 'Itonics Pusher', url: '/itonics-new'},
  {name: 'Itonics Pusher (Legacy)', url: '/itonics'},
];

const Navigation = (props) => {
  return (
    <nav className="sticky top-0 bg-white shadow-md z-50">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex items-center justify-between h-16">
          <div className="flex items-center">
            <h1 className="text-xl flex items-center">
              <img 
                src={logo} 
                alt="Logo" 
                className="h-8 w-8 mr-2" 
              />
              <Link to="/">LOTR</Link>
            </h1>
            <div className="ml-10 flex space-x-4">
              {LINKS.map((l, i) => {
                return (
                  <Link 
                    key={i}
                    to={l.url}
                    className="text-gray-700 hover:text-gray-900 px-3 py-2 rounded-md text-sm"
                  >
                    {l.name}
                  </Link>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </nav>);
};

export default Navigation;
