import React, {useState} from 'react';
import {useNavigate, useLocation} from 'react-router-dom';
import {useAuth} from '../context/auth-context';

const Login = (props) => {
  const navigate = useNavigate();
  const location = useLocation();

  const { login } = useAuth(); // Access login function from context
  const [loginData, setLoginData] = useState({email: '', password: ''});
  const [error, setError] = useState(null); // Local error state for the page

  const queryParams = new URLSearchParams(location.search);
  const redirectPath = queryParams.get('redirect') || '/';

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(null); // Reset previous error
    const success = await login(loginData.email, loginData.password);

    if (!success) {
      setError('Login failed. Please check your credentials and try again.');
    } else {
      navigate(redirectPath);
    }
  };

  const onChange = (event) => {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    loginData[name] = value;
    setLoginData({...loginData});
  }

  return (<div className="flex items-center justify-center min-h-screen bg-gray-100">
    <div className="w-full max-w-md p-8 space-y-6 bg-white rounded-lg shadow-md">
      <h1 className="text-2xl font-bold text-center text-gray-800">Login</h1>
      <form onSubmit={handleSubmit} className="space-y-4">
        <div>
          <label className="block text-sm font-medium text-gray-700">
            Email
            <input
              type="text"
              name="email"
              onChange={onChange}
              value={login.email}
              className="w-full mt-1 px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
              placeholder="Enter your email"
            />
          </label>
        </div>
        <div>
          <label className="block text-sm font-medium text-gray-700">
            Password
            <input
              type="password"
              name="password"
              onChange={onChange}
              value={login.password}
              className="w-full mt-1 px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
              placeholder="Enter your password"
            />
          </label>
        </div>
        <div>
          <input
            type="submit"
            value="Login"
            className="w-full px-4 py-2 text-white bg-primary rounded-md hover:bg-green-700 focus:ring-2 focus:ring-primary focus:ring-offset-2 cursor-pointer"
          />
        </div>
      </form>
      {error && <div className="text-red-500 mt-4">{error}</div>}
    </div>
  </div>);

}

export default Login;
