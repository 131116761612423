import React, { createContext, useContext, useState, useEffect } from 'react';
import Cookies from 'universal-cookie'; 
import ItonicsLogService from '../services/itonicslog.service';

const AUTH_API = 'https://auth2.nextatlas.com/api/v3';

// Create a context for authentication
const AuthContext = createContext();

// Create a custom hook to access authentication context
export const useAuth = () => {
  return useContext(AuthContext);
};

const services = {
  itonicsLogService: new ItonicsLogService()
};

// AuthProvider component
export const AuthProvider = ({ children }) => {


  const [account, setAccount] = useState(null);
  const [token, setToken] = useState(null);
  const [loading, setLoading] = useState(true);

  const cookies = new Cookies(); // Initialize universal-cookie

  // Get base domain (excluding subdomains)
  const getBaseDomain = () => {
    const { hostname } = window.location;
    const domainParts = hostname.split('.').slice(-2).join('.');  // Get the base domain (example.com from app.example.com)
    return domainParts;
  };

  // Check for token in cookies when the component mounts
  useEffect(() => {
    const savedToken = cookies.get('token'); // Retrieve token from cookies
    if (savedToken) {
      setLoading(true);
      setToken(savedToken);
      fetchAccount(savedToken); // Fetch account data if token exists
    } else {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    if (account) {
      setLoading(false);
    }
  }, [account]);

  // Function to get the account data based on the token
  const fetchAccount = async (token) => {
    if (!token) return; // If no token is passed, return early (do not attempt to fetch)

    try {
      console.log('OK');

      const response = await fetch(`${AUTH_API}/auth/validateadmin`, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (!response.ok) {
        console.log('NO OK');
        setAccount(null);
        setLoading(false);
        return;
        // throw new Error('Failed to fetch account data');
      }

      const data = await response.json();
      setAccount(data.response); // Set the account information to state
      
      Object.keys(services).forEach(k => {
        services[k].setHeader('Authorization', 'Bearer ' + token);
      });

      setLoading(false);
    } catch (error) {
      console.log('WTF');
      console.error('Failed to fetch account data:', error);
    }
  };

  // Function to log in
  const login = async (email, password) => {
    try {
      const response = await fetch(`${AUTH_API}/auth/authenticate`, {
        method: 'POST',
        body: JSON.stringify({ email, password, mode: 'app' }),
        headers: {
          'Content-Type': 'application/json',
        },
      });

      if (!response.ok) {
        throw new Error('Invalid email or password');
      }

      const data = await response.json();
      const token = data.response.token;

      // Set cookie properties
      const baseDomain = getBaseDomain();  // Get the base domain (example.com)

      // Save token in cookies with additional properties
      cookies.set('token', token, {
        expires: new Date(Date.now() + 86400000), // Cookie duration: 1 day
        domain: baseDomain, // Domain: base domain (example.com)
        path: '/', // Path: the root of the domain
        sameSite: 'Strict', // SameSite policy (Strict or Lax)
      });

      setToken(token);

      // Get the user account info after login
      await fetchAccount(token);

      // Redirect to the desired page (e.g., /itonics-new or home page)
      // navigate('/itonics-new'); // Or wherever you want to redirect after login

      return true; // Indicate success
    } catch (error) {
      console.error('Login failed:', error);
      return false; // Indicate failure
    }
  };

  // Function to log out
  const logout = () => {
    cookies.remove('token', { path: '/' }); // Remove the token cookie
    setToken(null);
    setAccount(null);
  };

  if (loading) {
    return ''; // empty until validates
  }

  return (
    <AuthContext.Provider value={{account, token, login, logout, services}}>
      {children}
    </AuthContext.Provider>
  );
};
