import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import heroImage from '../assets/hero.jpg';

const Home = () => {
  return (
    <div className="min-h-screen bg-gray-100"> {/* Background for the rest of the page */}
      {/* Hero Section */}
      <div
        className="relative w-full h-64 bg-cover bg-center"
        style={{ backgroundImage: `url(${heroImage})` }} // Background image for hero
      >
        {/* Black transparent overlay */}
        <div className="absolute inset-0 bg-black opacity-50"></div>

        {/* Hero Content */}
        <div className="absolute inset-0 flex items-center justify-center text-center text-white">
          <div>
            <h1 className="text-4xl">Log Of The Rings</h1>
            <h2 className="text-xl mt-2">Nextatlas Log Monitor</h2>
          </div>
        </div>
      </div>

      {/* Content below the hero */}
      <div className="py-8 px-4 text-center">
        {/* Itonics New Link */}
        <div className="mt-8">
          <h3 className="text-xl text-teal-600 hover:text-teal-700">
            <Link to="/itonics-new">ITONICS PUSHER</Link>
          </h3>
          <p className="text-md text-gray-500">The new Log Monitor for Itonics</p>
        </div>

        {/* Legacy Itonics Link */}
        <div className="mt-8">
          <h3 className="text-xl text-teal-600 hover:text-teal-700">
            <Link to="/itonics">ITONICS PUSHER (legacy)</Link>
          </h3>
          <p className="text-md text-gray-500">The Monitor for the legacy pusher (Python), dismissed in October 2024</p>
        </div>

        {/* Footer */}
        <div className="mt-16 text-gray-600 text-sm">
          <p>&copy; 2024 Nextatlas. All Rights Reserved.</p>
        </div>
      </div>
    </div>
  );
};
export default Home;

